import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import SponsorsDefaultLayout from './Layout/SponsorsDefaultLayout';
import SponsorsSection from './SponsorsSection';

function SponsorsContainer(props: ISectionComponentProps): JSX.Element {
    const { section } = props
    if(section.layout === SectionLayoutType.DEFAULT) {
        return <SponsorsDefaultLayout {...props}/>
    }
    return <SponsorsSection {...props}/>
}

export default SponsorsContainer;