import { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box'

import Tabs from '@/shared/components/Tabs/Tabs'
import { Headline3 } from '@/shared/components/Typography';
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder';
import Carousel from './Layout/CarouselSponsors'
import List from './Layout/ListSponsors'
import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import { useIntl } from 'react-intl';
import { sectionMessages } from '../messages';
import { useRouter } from 'next/router';
import Loader from '@/shared/components/Loader/Loader';
import { getSponsors } from 'services/sponsors';
import { getSponsorTabKey } from './utils';
import { useClub } from 'contexts/club';

function SponsorsSection(props: ISectionComponentProps): JSX.Element {
    const { section: { layout, content, id, name = '' }, data } = props
    const { sponsorCategories } = data
    const [sponsors, setSponsors] = useState(data.sponsors)
    const [isFetching, setIsFetching] = useState(false)
    const { prefix } = useClub()

    const router = useRouter()
    const queryStringKey = getSponsorTabKey(id);
    const selectedTabQuery = Number(router.query[queryStringKey])
    const intl = useIntl()

    const categoryTabs = useMemo(() => content?.categories?.map(contentCategory => {
        const category = sponsorCategories.find(e => e.id === contentCategory.id)
        return {
            title: category?.name,
            id: contentCategory.id
        }
    }).filter(e => e.title) ?? [], [content?.categories, sponsorCategories])

    let selectedIndex = categoryTabs.findIndex(e => e.id === selectedTabQuery);

    if (selectedIndex < 0)
        selectedIndex = 0

    const filteredSponsors = useMemo(() => {
        if (categoryTabs && categoryTabs.length) {
            return sponsors.filter(e => e.category?.id === categoryTabs[selectedIndex]?.id)
        }
        return sponsors
    }, [sponsors, categoryTabs, selectedIndex])

    const isEmpty = filteredSponsors.length === 0

    function onClickTab(tab) {
        router.query[queryStringKey] = tab.id
        router.replace(router, undefined, { shallow: true })
        setIsFetching(true)
        getSponsors({ clubPrefix: prefix, params: { _sponsor_categories: tab.id } })
            .then((response) => setSponsors(response))
            .finally(() => {
                setIsFetching(false)
            })
    }

    return (
        <Box>
            {name && (
                <Box display='flex' justifyContent='center' alignItems='center' pb={4}>
                    <Headline3>{name}</Headline3>
                </Box>
            )}
            {categoryTabs && categoryTabs.length > 1 && (
                <Box display='flex' justifyContent='center' alignItems='center' pb={4}>
                    <Tabs onClick={onClickTab} tabs={categoryTabs} selectedIndex={selectedIndex} />
                </Box>
            )}
            {isFetching && (
                <Loader />
            )}
            {(isEmpty && !isFetching) && (
                <EmptyPlaceholder title={intl.formatMessage(sectionMessages['sponsors.empty'])} fullScreenSize={6} smallScreenSize={3} />
            )}
            {layout === SectionLayoutType.CAROUSEL && (!isEmpty && !isFetching) && (
                <Carousel sponsorCategories={sponsorCategories} sponsors={filteredSponsors} randomize={content?.randomize}/>
            )}
            {layout === SectionLayoutType.LIST && (!isEmpty && !isFetching) && (
                <List sponsorCategories={sponsorCategories} sponsors={filteredSponsors} />
            )}
        </Box>
    )
}

export default SponsorsSection;