import { ISponsor, ISponsorCategory } from '@/shared/models/Sponsor'
import  { request } from './api'
import qs from 'querystring';

export function getSponsors({ clubPrefix, params }: { clubPrefix: string, params: { [key in string]: any} }): Promise<ISponsor[]> {
    return request({
        endpoint: `/sponsors?${qs.stringify(params)}`,
        method: 'GET',
        clubPrefix
    })
}

export function getSponsorCategories({ clubPrefix }: { clubPrefix: string }): Promise<ISponsorCategory[]> {
    return request({
        endpoint: '/sponsors/categories',
        method: 'GET',
        clubPrefix
    })
}