import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useSponsorsStaticStyles } from '../styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@/shared/components/Card';
import Square from 'components/Sections/Placeholders/Square';
import { Paragraph2 } from '@/shared/components/Typography'
import { ISponsorsSectionData } from '../types';

function ListSponsors(props: ISponsorsSectionData): JSX.Element {
    const { sponsors } = props
    const isContainerCenter = sponsors.length < 6
    const classes = useSponsorsStaticStyles({ isContainerCenter })
    return (
        <Box>
            <Grid container spacing={2} className={classes.listContainer}>
                {sponsors.map((sponsor) => (
                    <Grid key={sponsor.id} item md={2} sm={4} xs={4} className={classes.listItem}>
                        <Card>
                            <a
                                href={sponsor.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className={classes.cardContainer}>
                                    <Square
                                        media={sponsor.banner}
                                        failoverComponent={(
                                            <div className={classes.sponsorName}>
                                                <Paragraph2>
                                                    {sponsor.name}
                                                </Paragraph2>
                                            </div>
                                        )}
                                        mods='media'
                                    />
                                </div>
                            </a>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ListSponsors;
