import ImgLoader from '@/shared/components/Img/ImgLoader';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import Box from '@material-ui/core/Box'
import 'react-alice-carousel/lib/alice-carousel.css';
import { shuffle } from '@/shared/utils/lodashFunc';
import { Paragraph2 } from '@/shared/components/Typography'
import { useSponsorsDynamicStyles } from '../styles';
import { ISponsorsSectionData } from '../types';

const responsive = {
    0: {
        items: 1
    },
    1024: {
        items: 3
    }
};


function CarouselSponsors(props: ISponsorsSectionData): JSX.Element {
    const { sponsors, randomize } = props
    const classes = useSponsorsDynamicStyles();
    const orderedSponsors = React.useMemo(() => randomize ? shuffle(sponsors) : sponsors, [sponsors])

    function Carousel(): JSX.Element {
        return (
            <AliceCarousel
                responsive={responsive}
                mouseTracking
                keyboardNavigation
                infinite
                autoPlay
                autoPlayInterval={1000}
                animationDuration={2500}
                disableDotsControls
                disableButtonsControls
                items={orderedSponsors.map((sponsor) => {
                    return (
                        <a
                            key={sponsor.id}
                            href={sponsor.url}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.sponsorName}
                        >
                            <div className={classes.media}>
                                <ImgLoader
                                    src={sponsor.banner}
                                    className={classes.image}
                                    failoverComponent={(<div className={classes.failOverName}>
                                        <Paragraph2>{sponsor.name}</Paragraph2>
                                    </div>)}
                                />
                            </div>
                        </a>
                    );
                })}
            />
        )
    }

    const CarouselComponent = React.memo(Carousel);

    return (
        <Box className={classes.container}>
            <CarouselComponent/>
        </Box>
    );
}

export default CarouselSponsors;
