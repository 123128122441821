import { ISponsor } from '@/shared/models/Sponsor';
import { groupBy } from '@/shared/utils/lodashFunc';
import { ISectionComponentProps } from 'components/Sections/utils';
import { useMemo } from 'react';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Headline2, Headline3 } from '@/shared/components/Typography';
import SectionCardMedia from 'components/Sections/SectionMedia/SectionCardMedia';
import { Paragraph2 } from '@/shared/components/Typography'
import { useSponsorsStaticStyles } from '../styles';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import joinElements from '@/shared/utils/joinElements';
import EmptyPlaceholder from 'components/Sections/Placeholders/EmptyPlaceholder';

export default function SponsorsDefaultLayout(props: ISectionComponentProps) {
    const { data, section } = props
    const { sponsors, sponsorCategories } = data
    const sponsorsByCategoryId = useMemo(() => {
        return groupBy(sponsors.map(e => ({ ...e, categoryId: e?.category ? `id_${e?.category?.id}` : 'no-sponsor' })), 'categoryId')
    }, [sponsors])
    const classes = useSponsorsStaticStyles({})
    const intl = useIntl()
    const isEmpty = sponsors?.length === 0
    return (
        <Box>
            <Box pb={4}>
                <Headline2>
                    {section.name}
                </Headline2>
            </Box>
            {isEmpty ?
                <EmptyPlaceholder title={intl.formatMessage(sectionMessages['sponsors.empty'])} fullScreenSize={6} smallScreenSize={3} /> :
                joinElements(Object.entries(sponsorsByCategoryId).map(([categoryId, data]) => renderSponsorCategory(categoryId, data)).filter(Boolean), <Box pb={4}/>)
            }
        </Box>
    )

    function renderSponsorCategory(categoryId: string, data: ISponsor[]) {
        if(data.length === 0) {
            return null
        }
        const categoryName = categoryId === 'no-sponsor'
            ? intl.formatMessage(sectionMessages['sponsors.label'])
            : sponsorCategories?.find(e => e.id === Number(categoryId.split('_')[1]))?.name || ''
        const hideCategoryName = (categoryId === 'no-sponsor' && sponsorCategories.length === 0) || !categoryName
        return (
            <Box>
                {!hideCategoryName && (
                    <Box pb={4}>
                        <Headline3>{categoryName}</Headline3>
                    </Box>
                )}
                <Grid container spacing={4}>
                    {data.map((sponsor) => (
                        <Grid
                            key={sponsor.id}
                            item lg={3} md={3} sm={6} xs={6}>
                                <SectionCardMedia
                                    media={sponsor.banner}
                                    title={sponsor.name}
                                    description={sponsor.info || ''}
                                    link={sponsor.url}
                                    failOverComponent={(
                                        <div className={classes.sponsorName}>
                                            <Paragraph2>
                                                {sponsor.name}
                                            </Paragraph2>
                                        </div>
                                    )}
                                    useFailOverComponent
                                    openLinkNewTab
                                    mediaMods={'sponsor|p-3'}
                                />
                            </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }
}