import { Colors } from '@/shared/styles/MuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useSponsorsDynamicStyles = makeStyles((theme) => ({
    media: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    image: {
        maxHeight: 96
    },
    container: {
        '& .alice-carousel': {
            backgroundColor: '#ffffff' //for the sponsors, we will add a white background for the box around a sponsor image, (ignore color mode)
        }
    },
    sponsorName: {
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 96
    },
    failOverName: {
        height: 96,
        '& > p': {
            color: Colors.DarkText
        }
    }
}));

export const useSponsorsStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabs: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

export const useSponsorsStaticStyles = makeStyles(theme => ({
    listContainer: {
        justifyContent: ({ isContainerCenter }: { isContainerCenter?: boolean; }) => isContainerCenter ? 'center' : 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start !important'
        }
    },
    container: {
        padding: theme.spacing(4, 4)
    },
    listItem: {
        minWidth: 175,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
        },
    },
    cardContainer: {
        padding: theme.spacing(2),
        backgroundColor: '#ffffff' //for the sponsors, we will add a white background for the box around a sponsor image, (ignore color mode)
    },
    noSponsors: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    sponsorName: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        // height: '100%',
        // width: '100%'
        '& > p': {
            color: Colors.DarkText
        }
    }
}))